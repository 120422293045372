<template>
  <!--
    The view for the Login Data Audit View
  -->
  <div class="loginDataAudit">
    <Portlet
      :title="$t('menu.loginDataAudit')"
      icon="lock"
    >
      <form>
        <div
          class="form-group col-12 mt-3"
        >
          <label>{{ $t('from') }}</label>
          <date-picker
            v-model="selectedDateFrom"
            :editable="true"
            :clearable="false"
            type="date"
            lang="en"
            format="DD.MM.YYYY"
            value-type="date"
            :first-day-of-week="1"
            class="ml-3"
          />
          <label class="ml-4">{{ $t('to') }}</label>
          <date-picker
            v-model="selectedDateTo"
            :editable="true"
            :clearable="false"
            type="date"
            lang="en"
            format="DD.MM.YYYY"
            value-type="date"
            :first-day-of-week="1"
            class="ml-3"
          />
          <button
            type="button"
            class="btn btn-primary btn-sm ml-3"
            @click="searchAudits()"
          >
            <font-awesome-icon
              class="mr-2"
              icon="search"
            />
            <span>{{ $t('search') }}</span>
          </button>
        </div>
      </form>
      <DatabaseAuditLogList
        v-if="selectedDateFrom && selectedDateTo"
        ref="databaseAuditLogList"
        :is-all-login-audit="true"
        :from="from"
        :to="to"
        :collapsed="false"
      />
    </Portlet>
  </div>
</template>

<script>
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';
import DatePicker from 'vue2-datepicker';

export default {
  name: "PeakLoginDataAuditView",
  components: {
    DatabaseAuditLogList: () => import('@/components/DatabaseAuditLogs/DatabaseAuditLogsList.vue'),
    DatePicker
  },
  mixins: [
    dateTimeMixin
  ],
  data () {
    return {
      componentIdsForAudit: null,
      selectedDateFrom: null,
      selectedDateTo: null
    }
  },
  computed: {
    from () {
      if(this.selectedDateFrom) {
        return this.dateTime_isoString(this.selectedDateFrom)
      }
      return null
    },
    to () {
      if(this.selectedDateTo) {
        return this.dateTime_isoString(this.selectedDateTo)
      }
      return null
    }
  },
  created () {
    this.selectedDateTo = new Date()
    this.selectedDateFrom = this.moment().add(-30, 'days').toDate()
  },
  mounted () {
    setTimeout(() => {
      this.searchAudits();        
    }, 1000);
    
  },
  methods: {
    searchAudits () {
      this.$refs.databaseAuditLogList.searchLoginAudits();
    }
  }
}
</script>